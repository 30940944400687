import React, { FC } from "react";
import { Small } from "../Typography";

interface IStatusBtnProps {
  title: string | React.ReactNode;
  type: string | number;
  colorMap?: string[];
}

const StatusBtn: FC<IStatusBtnProps | any> = ({
  title,
  type,
  colorMap = ["0", "1", "2", "3", "4", "5", "6", "7", "8"],
  ...rest
}: any) => {
  return (
    <Small sx={[styles(colorMap).badge, styles(colorMap)[type]]} {...rest}>
      {title}
    </Small>
  );
};

const styles: any = (colorMap: string[]) => ({
  badge: {
    lineHeight: 1.5,
    borderRadius: "1.03125rem",
    padding: "0.25rem 0.625rem",
    border: "0.0625rem solid transparent",
    cursor: "pointer",
  },
  [`${colorMap[0]}`]: {
    color: "#f72b50",
    backgroundColor: "#fee6ea",
  },
  [`${colorMap[1]}`]: {
    color: "#1F5A2D",
    backgroundColor: "#44814e1a",
  },
  [`${colorMap[2]}`]: {
    color: "#f7962b",
    backgroundColor: "#fef4e6",
  },
  [`${colorMap[3]}`]: {
    color: "#f7962b",
    backgroundColor: "#fef4e6",
  },
  [`${colorMap[4]}`]: {
    color: "#626563",
    backgroundColor: "#626b631a",
  },
  [`${colorMap[5]}`]: {
    color: "#f72b50",
    backgroundColor: "#fee6ea",
  },
  [`${colorMap[6]}`]: {
    color: "#f72b50",
    backgroundColor: "#fee6ea",
  },
  [`${colorMap[7]}`]: {
    color: "#f7962b",
    backgroundColor: "#fef4e6",
  },
  [`${colorMap[8]}`]: {
    color: "#7e3ff2",
    backgroundColor: "#efe5fd",
  },
});

export default StatusBtn;
