import { Subject } from "rxjs";

const subject = new Subject();

const initialState = {
  photo: "/static/background/user-cover-pic.svg",
};

let state = initialState;

const UserProfileStore = {
  init: (photo: string) => subject.next({ ...state, photo }),
  subscribe: (setState: any) => subject.subscribe(setState),
  update: (payload: any) => {
    state = {
      ...state,
      ...payload,
    };
    subject.next(state);
  },
  initialState,
};

export default UserProfileStore;
