import React, { FC, useEffect } from "react";
import { Select, MenuItem, FormGroup } from "@mui/material";
import { ukoTheme } from "../../../theme";
import { IFormSelectInputProps } from "./FormSelectInput";
import { IFormField, deepDerefrencer } from "../../../utils/form_factory";
import uniqueId from "../../../utils/generateId";

interface IConcatenatedSelectSelectProps extends Partial<IFormField> {
  parentInput?: IFormSelectInputProps;
  secondaryInput: IFormSelectInputProps;
  formControl: any;
}

const ConcatenatedSelectSelect: FC<IConcatenatedSelectSelectProps> = ({
  parentInput,
  secondaryInput,
  formControl,
  isDisabled,
  isHidden,
}) => {
  const theme = ukoTheme();

  return (
    <FormGroup
      sx={{
        display: "flex",
        flexDirection: "row",
        ...styles.groupWrapper,
        ...styles.input(theme),
      }}
    >
      <Select
        sx={{
          ...styles.selectField,
          ...styles.secondaryField,
        }}
        label={secondaryInput.label}
        variant="outlined"
        displayEmpty
        name={secondaryInput.name}
        notched={undefined}
        id={uniqueId()}
        onChange={formControl.handleChange}
        value={deepDerefrencer(formControl.values, secondaryInput.name)}
        onBlur={formControl.handleBlur}
        disabled={secondaryInput.isDisabled}
      >
        <MenuItem disabled value={""}>
          {secondaryInput.placeHolder}
        </MenuItem>
        {secondaryInput.options?.map((option: any, index) => {
          return (
            <MenuItem
              key={index}
              value={secondaryInput.selector?.value(option)}
            >
              {secondaryInput.selector?.label(option)}
            </MenuItem>
          );
        })}
      </Select>

      {parentInput && (
        <Select
          sx={{
            ...styles.selectField,
          }}
          id={uniqueId()}
          label={parentInput?.label}
          variant="outlined"
          placeholder={parentInput?.placeHolder}
          name={parentInput?.name}
          type={parentInput?.type || "select"}
          onChange={formControl.handleChange}
          value={deepDerefrencer(formControl.values, parentInput?.name)}
          error={Boolean(
            deepDerefrencer(formControl.touched, parentInput?.name) &&
              deepDerefrencer(formControl.errors, parentInput?.name),
          )}
          onBlur={formControl.handleBlur}
          disabled={parentInput?.isDisabled}
          hidden={parentInput?.isHidden}
        >
          <MenuItem disabled value={""}>
            {parentInput?.placeHolder}
          </MenuItem>
          {parentInput?.options?.map((option: any, index) => {
            return (
              <MenuItem
                key={index}
                value={parentInput?.selector?.value(option)}
              >
                {parentInput?.selector?.label(option)}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </FormGroup>
  );
};

const styles = {
  groupWrapper: {
    border: "2px solid #E5EAF2",
    width: "100%",
    borderRadius: 2,
  },
  selectField: {
    width: "50%",
  },
  secondaryField: {
    borderRadius: "0px",
    borderRight: "1px solid #dedede !important",
  },
  input: (theme: any, outlineColor?: string) => ({
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  }),
};

export default ConcatenatedSelectSelect;
