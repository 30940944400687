import React, { FC, useState } from "react";
import { deepDerefrencer, IFormField } from "../../../../utils/form_factory";
import { Box, Button } from "@mui/material";
import Chip from "@mui/material/Chip";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from "@mui/icons-material/Cancel";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import CloseIcon from "@mui/icons-material/Close";

import { useDrawerContext } from "../../../../contexts/DrawerContext";
import DrawerBox from "../../DrawerBox";
import { H4 } from "../../../Typography";
import VerificationButtonForm from "./VerificationButtonForm";
import KYCLoader from "../KYCLoader";
import KYCResults from "../KYCResults";
import { KycCheckService } from "./services/KYCCheck.service";
import toast from "react-hot-toast";

export enum VerificationStatus {
  VERIFIED = "Verified",
  UNVERIFIED = "Unverified",
  NOTCHECKED = "Not Checked",
}

export enum VerificationType {
  VERIFYPHONE = "VerifyPhone",
  VERIFYNIN = "VerifyNIN",
  CHECKCREDITSCORE = "CheckCreditScore",
  CHECKCREDITREPORT = "CheckCreditReport",
  VERIFYID = "VerifyID",
}

export enum PhoneType {
  PRIMARY = "primarycellphone",
  SECONDARY = "secondarycellphone",
}

export enum BtnDisplayType {
  FORM = "form",
  DETAILS = "details",
}

export const getVerificationStatus = (status: string) => {
  switch (status) {
    case "0":
      return VerificationStatus.NOTCHECKED;
    case "1":
      return VerificationStatus.VERIFIED;
    case "3":
      return VerificationStatus.UNVERIFIED;
    default:
      return undefined;
  }
};

export interface IVerificationButtonProps {
  formField: IFormField;
  formInstance: any;
  verificationType: VerificationType;
  verificationStatus?: VerificationStatus | undefined;
  phoneType?: PhoneType;
  client?: any;
  branch?: any;
  displayType: BtnDisplayType;
}

const VerificationButton: FC<IVerificationButtonProps> = (props) => {
  const { showDrawer, setShowDrawer, setDrawerId } = useDrawerContext();
  const [verifying, setVerifying] = useState<boolean>(false);
  const [checkResults, setCheckResults] = useState<any>(null);
  const [verificationStatus, setVerificationStatus] = useState<
    VerificationStatus | undefined
  >(props.verificationStatus);
  const [retryParams, setRetryParams] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);

  const isValid = () => {
    return (
      deepDerefrencer(props?.formInstance?.values, props.formField.name) &&
      !deepDerefrencer(props?.formInstance?.errors, props.formField.name)
    );
  };

  const handleOpenDrawer = async () => {
    setDrawerId(`${props.verificationType}-${props.formField.name}`);
    setShowDrawer({ ...showDrawer, ...{ right: true } });
  };

  const closeDrawer = () => {
    setVerifying(false);
    setCheckResults(null);
    setShowDrawer({ ...showDrawer, ...{ right: false } });
  };

  const handleVerifyPhone = async (data: any) => {
    setVerifying(true);
    try {
      const res: any = await KycCheckService.kycCheckPhoneService(data);
      toast.success(res?.message);
      setCheckResults(res?.result);

      if (res?.result?.result?.status === "FAILED") {
        setVerificationStatus(VerificationStatus.NOTCHECKED);
      }
    } catch (error) {
      toast.error("Failed to make verification request");
    } finally {
      setVerifying(false);
    }
  };

  const handleValidId = async (data: any) => {
    setVerifying(true);
    try {
      const res: any = await KycCheckService.kycCheckValidIdService(data);
      toast.success(res?.message);
      setCheckResults(res?.result);

      if (res?.result?.result?.status === "SUCCESSFUL") {
        await handleUpdateValidID(1, res?.result);
        setVerificationStatus(VerificationStatus.VERIFIED);
      }

      if (res?.result?.result?.status === "FAILED") {
        await handleUpdateValidID(0, res?.result);
        setVerificationStatus(VerificationStatus.NOTCHECKED);
      }

      if (res?.result?.status == 5) {
        await handleUpdateValidID(3, res?.result);
        setVerificationStatus(VerificationStatus.UNVERIFIED);
      }
    } catch (error) {
      toast.error("Failed to make verification request");
    } finally {
      setVerifying(false);
    }
  };

  const handleIfMatched = async () => {
    setVerificationStatus(VerificationStatus.VERIFIED);
    setSubmitting(true);

    if (props.displayType === BtnDisplayType.FORM) {
      if (props.phoneType === PhoneType.PRIMARY) {
        props.formInstance.setFieldValue(
          "user_details.primary_phone_validity_status",
          1,
        );
      } else {
        props.formInstance.setFieldValue(
          "user_details.secondary_phone_validity_status",
          1,
        );
      }
    } else {
      await handleUpdateKYCStatus(checkResults?.id, 1);
    }
    setSubmitting(false);
    closeDrawer();
  };

  const handleIfNotMatched = async () => {
    setVerificationStatus(VerificationStatus.UNVERIFIED);
    setSubmitting(true);
    if (props.displayType === BtnDisplayType.FORM) {
      if (props.phoneType === PhoneType.PRIMARY) {
        props.formInstance.setFieldValue(
          "user_details.primary_phone_validity_status",
          3,
        );
      } else {
        props.formInstance.setFieldValue(
          "user_details.secondary_phone_validity_status",
          3,
        );
      }
    } else {
      await handleUpdateKYCStatus(checkResults?.id, 3);
    }
    setSubmitting(false);
    closeDrawer();
  };

  const handleUpdateValidID = async (resultStatus: number, result: any) => {
    if (props.displayType === BtnDisplayType.FORM) {
      props.formInstance.setFieldValue(
        "user_details.nin_validity_status",
        resultStatus,
      );
    } else {
      await handleUpdateKYCStatus(result?.id, resultStatus);
    }
  };

  const handleSubmission = async (data: any) => {
    switch (props.verificationType) {
      case VerificationType.VERIFYPHONE:
        const payload1: any = {
          client: props.client,
          check_attribute: props.phoneType,
          phonenumber: deepDerefrencer(
            props?.formInstance?.values,
            props.formField.name,
          ),
          branch: data.branch,
        };
        setRetryParams(payload1);
        await handleVerifyPhone(payload1);
        break;
      case VerificationType.VERIFYID:
        const payload2: any = {
          ...data,
          client: props.client,
          check_attribute: "nin",
          branch: data.branch,
          nin: data.nin,
          [data.applies_to_temp]: data[data.applies_to_temp],
          document_id: data.document_id,
        };
        setRetryParams(payload2);
        await handleValidId(payload2);
        break;
      default:
        break;
    }
  };

  const handleTryAgain = async () => {
    switch (props.verificationType) {
      case VerificationType.VERIFYPHONE:
        await handleVerifyPhone(retryParams);
        break;
      case VerificationType.VERIFYID:
        await handleValidId(retryParams);
        break;
      default:
        break;
    }
  };

  const handleUpdateKYCStatus = async (id: string, status: any) => {
    try {
      await KycCheckService.kycCheckUpdate(id, { status });
    } catch (error) {
      toast.error("Failed to update KYC status");
    }
  };

  return (
    <>
      {(verificationStatus === undefined ||
        verificationStatus === VerificationStatus.NOTCHECKED) && (
        <Button
          disabled={!isValid()}
          size="small"
          variant="contained"
          onClick={handleOpenDrawer}
          sx={{ paddingX: 2, paddingY: 0.5, fontSize: "14px" }}
        >
          <FindReplaceIcon sx={{ fontSize: "16px", marginRight: "2px" }} />
          Verify
        </Button>
      )}

      {verificationStatus === VerificationStatus.VERIFIED && (
        <Chip
          icon={<VerifiedIcon style={{ width: "0.7em" }} />}
          label="MATCHED"
          color="success"
          sx={{ fontSize: "0.7125rem", height: "22px" }}
        />
      )}

      {verificationStatus === VerificationStatus.UNVERIFIED && (
        <Chip
          icon={<CancelIcon style={{ width: "0.7em" }} />}
          label="NOT MATCHED"
          color="error"
          sx={{ fontSize: "0.7125rem", height: "22px" }}
        />
      )}

      <DrawerBox
        minWidth={600}
        maxWidth={600}
        selectedDrawerId={`${props.verificationType}-${props.formField.name}`}
        anchor="right"
        onClose={closeDrawer}
      >
        <Box sx={{ height: "100%" }}>
          <Box sx={{ ...styles.title }}>
            <H4 fontWeight={"bold"}>
              {props.verificationType === VerificationType.VERIFYPHONE
                ? "Phone Number "
                : "ID "}{" "}
              Verification
            </H4>
            <Button
              onClick={closeDrawer}
              color="inherit"
              variant={"contained"}
              sx={{ ...styles.closeButton }}
            >
              <CloseIcon />
            </Button>
          </Box>
          <Box sx={{ width: "100%", height: "100%", padding: 2 }}>
            {!verifying && !checkResults && (
              <VerificationButtonForm
                verificationType={props.verificationType}
                handleSubmit={(data) => {
                  handleSubmission(data);
                }}
                initialData={{
                  payload: deepDerefrencer(
                    props?.formInstance?.values,
                    props.formField.name,
                  ),
                  branch: props.branch,
                }}
              />
            )}
            {verifying && (
              <KYCLoader
                verificationType={
                  props.verificationType === VerificationType.VERIFYPHONE
                    ? "phone number"
                    : "NIN"
                }
              />
            )}
            {!verifying && checkResults && (
              <KYCResults
                results={checkResults}
                resultsType={props.verificationType}
                handleRetryAgain={handleTryAgain}
                handleIfMatched={handleIfMatched}
                handleIfNotMatched={handleIfNotMatched}
                confirming={submitting}
              />
            )}
          </Box>
        </Box>
      </DrawerBox>
    </>
  );
};

const styles = {
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E5EAF2",
    padding: 2,
    position: "sticky",
    backgroundColor: "white",
  },
  closeButton: {
    borderRadius: "50%",
    padding: "0 !important",
    width: "2rem",
    height: "2rem",
    minWidth: "2rem",
    backgroundColor: "white",

    "&:hover": {
      backgroundColor: "inherit.main",
      color: "black",
    },
  },
};

export default VerificationButton;
