import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { formatDateToDDMMYYYY } from "../../../utils/date_formatter";
import { figureFormatter } from "../../../utils/figure_formatter";
import { getName } from "../../Clients/models/ColumnShape";
import StatusBtn from "../../../components/UI/StatusBtn";
import { useNavigate } from "react-router-dom";

interface IDetailsInfo {
  entityDetails: any;
}

const LoanDisbursementReversal: FC<IDetailsInfo> = ({ entityDetails }) => {
  const navigate = useNavigate();

  return (
    <Card>
      <CardContent>
        <Typography
          sx={{
            fontSize: 14,
            mb: 1.5,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          gutterBottom
        >
          Loan Disbursement Reversal
          <Box
            sx={{ display: "inline-block" }}
            onClick={() => {
              navigate(`/loans/loan-details/${entityDetails?.id}`);
            }}
          >
            <StatusBtn type={4} title="More ..." />
          </Box>
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Loan No:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {entityDetails?.loan_no}
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Branch:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {entityDetails?.branch?.name}
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Client:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {getName(
            entityDetails?.account_details?.user_details,
            entityDetails?.account_details?.client_details?.client_type,
          )}
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Principal:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {figureFormatter({ figure: entityDetails?.principal })}
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Total Paid:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {figureFormatter({ figure: entityDetails?.amount_paid })}
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Outstanding Balance:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {figureFormatter({ figure: entityDetails?.current_balance })}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default LoanDisbursementReversal;
