import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { FC, useState } from "react";
import { AlertMessage } from "../../../AlertMessage";
import { H6 } from "../../../../Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDrawerContext } from "../../../../../contexts/DrawerContext";
import CreditReportDetails from "./CreditReportDetails";

interface ICreditReportProfilesProps {
  profiles: any;
  requestPayload: any;
  handleDownloadReport: any;
}

const CreditReportProfiles: FC<ICreditReportProfilesProps> = (props) => {
  const { setLoadingDrawerContent } = useDrawerContext();
  const [showReport, setShowReport] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<any>({});
  const [report, setReport] = useState<any>(null);

  const hasNoProfile = () => {
    return !props.profiles.formal && !props.profiles.informal;
  };

  const handleGetProfileDetails = async (profile: any) => {
    setLoadingDrawerContent(true);
    const filters = {
      ...props.requestPayload,
      ...profile,
    };
    setCurrentFilters(filters);

    const results: any = await props.handleDownloadReport(filters, "json");

    setLoadingDrawerContent(false);
    if (results) {
      setReport(results);
      setShowReport(true);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      {!showReport ? (
        <Box sx={{ paddingBottom: 5, width: "100%" }}>
          {hasNoProfile() && (
            <Box>
              <AlertMessage
                isOpen={true}
                message={
                  "We could not find any credit report profiles for this client."
                }
                type="error"
                closeAlert={() => {}}
              />
            </Box>
          )}

          {props.profiles.formal && (
            <Box marginBottom={2} width={"100%"}>
              <H6 sx={styles.profileTitle}>Formal Profiles</H6>

              {props.profiles.formal.individuals && (
                <List
                  sx={styles.listContainer}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component="div"
                      id="nested-list-subheader"
                      color="primary"
                      sx={styles.subheader}
                    >
                      Individual
                    </ListSubheader>
                  }
                >
                  {props.profiles.formal.individuals.map(
                    (profile: any, index: number) => (
                      <ListItemButton
                        key={`formal-individual-${index}`}
                        onClick={() =>
                          handleGetProfileDetails({
                            individual_id: profile?.individual_id,
                          })
                        }
                      >
                        <ListItemText
                          primary={`${profile?.surname} ${profile?.forename1}`}
                        />
                        <ArrowForwardIosIcon />
                      </ListItemButton>
                    ),
                  )}
                </List>
              )}

              {props.profiles.formal.nonindividuals && (
                <List
                  sx={styles.listContainer}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component="div"
                      id="nested-list-subheader"
                      color="primary"
                      sx={styles.subheader}
                    >
                      Non Individual
                    </ListSubheader>
                  }
                >
                  {props.profiles.formal.nonindividuals.map(
                    (profile: any, index: number) => (
                      <ListItemButton
                        onClick={() =>
                          handleGetProfileDetails({
                            non_individual_id: profile?.non_individual_id,
                          })
                        }
                        key={`formal-non-individual-${index}`}
                      >
                        <ListItemText primary={profile?.business_name} />
                        <ArrowForwardIosIcon />
                      </ListItemButton>
                    ),
                  )}
                </List>
              )}
            </Box>
          )}

          {props.profiles.informal && (
            <Box>
              <H6 sx={styles.profileTitle}>Informal Profiles</H6>

              {props.profiles.informal.individuals && (
                <List
                  sx={styles.listContainer}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component="div"
                      id="nested-list-subheader"
                      color="primary"
                      sx={styles.subheader}
                    >
                      Individual
                    </ListSubheader>
                  }
                >
                  {props.profiles.informal.individuals.map(
                    (profile: any, index: number) => (
                      <ListItemButton
                        key={`informal-individual-${index}`}
                        onClick={() =>
                          handleGetProfileDetails({
                            individual_id: profile?.individual_id,
                          })
                        }
                      >
                        <ListItemText
                          primary={`${profile?.surname} ${profile?.forename1}`}
                        />
                        <ArrowForwardIosIcon />
                      </ListItemButton>
                    ),
                  )}
                </List>
              )}

              {props.profiles.informal.nonindividuals && (
                <List
                  sx={styles.listContainer}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component="div"
                      id="nested-list-subheader"
                      color="primary"
                      sx={styles.subheader}
                    >
                      Non Individual
                    </ListSubheader>
                  }
                >
                  {props.profiles.informal.nonindividuals.map(
                    (profile: any, index: number) => (
                      <ListItemButton
                        key={`informal-non-individual-${index}`}
                        onClick={() =>
                          handleGetProfileDetails({
                            non_individual_id: profile?.non_individual_id,
                          })
                        }
                      >
                        <ListItemText primary={profile?.business_name} />
                        <ArrowForwardIosIcon />
                      </ListItemButton>
                    ),
                  )}
                </List>
              )}
            </Box>
          )}
        </Box>
      ) : (
        <CreditReportDetails
          handleDownloadReport={props.handleDownloadReport}
          data={report}
          currentFilters={currentFilters}
        />
      )}
    </Box>
  );
};

const styles = {
  profileTitle: {
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: 1,
  },
  listContainer: {
    width: "100%",
    bgcolor: "background.paper",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    marginBottom: 2,
  },
  subheader: {
    fontSize: 14,
    fontWeight: "semibold",
  },
};

export default CreditReportProfiles;
