import React, { FC } from "react";
import { H6, Span } from "../../../../Typography";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FlexBox from "../../../../FlexBox";
import { ukoTheme } from "../../../../../theme";
import CollapsibleSection from "../../CollapsibleSection";
import DownloadIcon from "@mui/icons-material/Download";

interface ResultsCreditReportProps {
  data: any;
  currentFilters: any;
  handleDownloadReport: (filters: any, type: string) => void;
}

const CreditReportDetails: FC<ResultsCreditReportProps> = (props) => {
  const theme = ukoTheme();
  const data = props?.data?.result;
  const clientType = Object.keys(props?.data?.parameter || {}).includes(
    "individual_id",
  )
    ? "Individual"
    : "Non-Individual";

  return (
    <Box sx={{ paddingBottom: 5 }}>
      <Box sx={styles.container} marginBottom={2}>
        <FlexBox gap={2} alignItems={"flex-start"}>
          <Box sx={styles.iconCircle}>
            {data?.customer_details?.Photo ? (
              <Avatar
                alt={data?.customer_details?.Name}
                src={data?.customer_details?.Photo}
              />
            ) : (
              <AccountCircleIcon
                color="primary"
                sx={{ height: "3.8rem", width: "3.8rem" }}
              />
            )}
          </Box>
          <FlexBox flexDirection={"column"} gap={0.8}>
            <Grid container spacing={1}>
              {clientType === "Individual" ? (
                <Grid item xs={6}>
                  <FlexBox flexDirection={"column"} gap={0.8}>
                    <Span sx={styles.labelValue}>
                      <Span color={theme.palette.common.black}>Name:</Span>{" "}
                      {data?.customer_details?.Name || "---"}
                    </Span>
                    <Span sx={styles.labelValue}>
                      <Span color={theme.palette.common.black}>
                        Date of Birth:
                      </Span>{" "}
                      {data?.customer_details?.Date_of_Birth || "---"}
                    </Span>
                    <Span sx={styles.labelValue}>
                      <Span color={theme.palette.common.black}>Tel:</Span>{" "}
                      {data?.customer_details?.Contact || "---"}
                    </Span>
                    <Span sx={styles.labelValue}>
                      <Span color={theme.palette.common.black}>Address:</Span>{" "}
                      {data?.customer_details?.Address || "---"}
                    </Span>
                  </FlexBox>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <FlexBox flexDirection={"column"} gap={0.8}>
                    <Span sx={styles.labelValue}>
                      <Span color={theme.palette.common.black}>
                        Trading Name:
                      </Span>{" "}
                      0709369701
                      {data?.customer_details?.trading_name || "---"}
                    </Span>
                    <Span sx={styles.labelValue}>
                      <Span color={theme.palette.common.black}>
                        Business Type:
                      </Span>{" "}
                      {data?.customer_details?.business_type || "---"}
                    </Span>
                    <Span sx={styles.labelValue}>
                      <Span color={theme.palette.common.black}>Industry:</Span>{" "}
                      {data?.customer_details?.industry || "---"}
                    </Span>
                    <Span sx={styles.labelValue}>
                      <Span color={theme.palette.common.black}>
                        Date Registered:
                      </Span>{" "}
                      {data?.customer_details?.date_registered || "---"}
                    </Span>
                  </FlexBox>
                </Grid>
              )}

              {props?.data?.client?.clientType === "Individual" && (
                <Grid item xs={6}>
                  <FlexBox flexDirection={"column"} gap={0.8}>
                    <Span sx={styles.labelValue}>
                      <Span color={theme.palette.common.black}>
                        Nationality:
                      </Span>{" "}
                      {props?.data?.client?.nationality || "---"}
                    </Span>
                    <Span sx={styles.labelValue}>
                      <Span color={theme.palette.common.black}>
                        Marital Status:{" "}
                      </Span>{" "}
                      {props?.data?.client?.marital_status || "---"}
                    </Span>
                    <Span sx={styles.labelValue}>
                      <Span color={theme.palette.common.black}>Gender: </Span>{" "}
                      {props?.data?.client?.gender || "---"}
                    </Span>
                  </FlexBox>
                </Grid>
              )}
            </Grid>
          </FlexBox>
        </FlexBox>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Physical Address History">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  SNo
                </H6>
                <Span sx={styles.vlabelValue}>
                  {data?.physical_address_history?.SNo || "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Address
                </H6>
                <Span sx={styles.vlabelValue}>
                  {data?.physical_address_history?.Address || "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Ownership
                </H6>
                <Span sx={styles.vlabelValue}>
                  {data?.physical_address_history?.Ownership || "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Period at Address
                </H6>
                <Span sx={styles.vlabelValue}>
                  {data?.physical_address_history?.Period_at_Address || "---"}
                </Span>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Postal Address History">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  SNo
                </H6>
                <Span sx={styles.vlabelValue}>
                  {data?.postal_address_history?.SNo || "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText || "---"}>
                  Address
                </H6>
                <Span sx={styles.vlabelValue}>
                  {data?.postal_address_history?.Address || "---"}
                </Span>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Contact History">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  SNo
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.contact_history?.Phone_Numbers &&
                    data?.contact_history?.Phone_Numbers[0]?.SNo) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Phone Number
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.contact_history?.Phone_Numbers &&
                    data?.contact_history?.Phone_Numbers[0]?.Phone_Number) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Emails
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.contact_history?.Emails &&
                    data?.contact_history?.Emails[0]?.SNo) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Email
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.contact_history?.Emails &&
                    data?.contact_history?.Emails[0]?.Email) ||
                    "---"}
                </Span>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Shareholder Details">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  SNo
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.shareholder_details &&
                    data?.shareholder_details[0]?.SNo) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Business Name
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.shareholder_details &&
                    data?.shareholder_details[0]?.Business_Name) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Shareholder type
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.shareholder_details &&
                    data?.shareholder_details[0]?.Stakeholder_Type) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Shareholder Category
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.shareholder_details &&
                    data?.shareholder_details[0]?.Stakeholder_Category) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Shareholder Percentage
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.shareholder_details &&
                    data?.shareholder_details[0]?.Shareholder_Percentage) ||
                    "---"}
                </Span>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Employment History">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  SNo
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.employment_history &&
                    data?.employment_history[0]?.SNo) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Employer Name
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.employment_history &&
                    data?.employment_history[0]?.Employer_Name) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Occupation
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.employment_history &&
                    data?.employment_history[0]?.Occupation) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Employment Date
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.employment_history &&
                    data?.employment_history[0]?.Employment_Date) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Income Band
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.employment_history &&
                    data?.employment_history[0]?.Income_Band) ||
                    "---"}
                </Span>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Scoring Details">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Evaluation Period
                </H6>
                <Span sx={styles.vlabelValue}>
                  {data?.scoring_details?.Evaluation_Period || "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Current Score
                </H6>
                <Span sx={styles.vlabelValue}>
                  {data?.scoring_details?.Current_Score || "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Current score Band
                </H6>
                <Span sx={styles.vlabelValue}>
                  {data?.scoring_details?.Current_Score_Band || "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Current Score Rating
                </H6>
                <Span sx={styles.vlabelValue}>
                  {data?.scoring_details?.Current_Score_Rating || "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Probability of Default percent
                </H6>
                <Span sx={styles.vlabelValue}>
                  {data?.scoring_details?.Probability_of_Default || "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Likelihood to default
                </H6>
                <Span sx={styles.vlabelValue}>
                  {data?.scoring_details?.Likelihood_to_Default || "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Score Explanation
                </H6>
                <Span sx={styles.vlabelValue}>
                  {data?.scoring_details?.Score_Explanation[0] || "---"}
                </Span>
              </Grid>

              <Grid item xs={12}>
                <H6>Score History</H6>

                <TableContainer component={Paper} elevation={0}>
                  <Table aria-label="simple table">
                    <TableHead sx={{ border: "1px solid #E5EAF2" }}>
                      <TableRow>
                        <TableCell>Period</TableCell>
                        <TableCell>Score</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.scoring_details?.Score_History?.map(
                        (item: any, index: number) => (
                          <TableRow sx={{ border: "1px solid #E5EAF2" }}>
                            <TableCell component="th" scope="row">
                              {item?.MonthYear}
                            </TableCell>
                            <TableCell>{item?.Score}</TableCell>
                          </TableRow>
                        ),
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Credit Applications Summary">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <H6>Total Credit Applications</H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.credit_applications_summary &&
                    data?.credit_applications_summary[0]
                      ?.Total_Credit_Applications) ||
                    "---"}
                </Span>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6 marginBottom={1}>Submitted Applications</H6>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Number of Applications
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        {(data?.credit_applications_summary &&
                          data?.credit_applications_summary[1]
                            ?.Submitted_Applications &&
                          data?.credit_applications_summary[1]
                            ?.Submitted_Applications?.No_of_Applications) ||
                          "---"}
                      </Span>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Amount
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {(data?.credit_applications_summary &&
                          data?.credit_applications_summary[1]
                            ?.Submitted_Applications &&
                          data?.credit_applications_summary[1]
                            ?.Submitted_Applications?.Amount) ||
                          "---"}
                      </Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6 marginBottom={1}>Pending Applications</H6>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Number of Applications
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        {(data?.credit_applications_summary &&
                          data?.credit_applications_summary[2]
                            ?.Pending_Applications &&
                          data?.credit_applications_summary[2]
                            ?.Pending_Applications?.No_of_Applications) ||
                          "---"}
                      </Span>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Amount
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {(data?.credit_applications_summary &&
                          data?.credit_applications_summary[2]
                            ?.Pending_Applications &&
                          data?.credit_applications_summary[2]
                            ?.Pending_Applications?.Amount) ||
                          "---"}
                      </Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6 marginBottom={1}>Approved Applications</H6>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Number of Applications
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        {(data?.credit_applications_summary &&
                          data?.credit_applications_summary[3]
                            ?.Approved_Applications &&
                          data?.credit_applications_summary[3]
                            ?.Approved_Applications?.No_of_Applications) ||
                          "---"}
                      </Span>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Amount
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {(data?.credit_applications_summary &&
                          data?.credit_applications_summary[3]
                            ?.Approved_Applications &&
                          data?.credit_applications_summary[3]
                            ?.Approved_Applications?.Amount) ||
                          "---"}
                      </Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6 marginBottom={1}>Rejected Applications</H6>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Number of Applications
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        {(data?.credit_applications_summary &&
                          data?.credit_applications_summary[4]
                            ?.Rejected_Applications &&
                          data?.credit_applications_summary[4]
                            ?.Rejected_Applications?.No_of_Applications) ||
                          "---"}
                      </Span>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Amount
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {(data?.credit_applications_summary &&
                          data?.credit_applications_summary[4]
                            ?.Rejected_Applications &&
                          data?.credit_applications_summary[4]
                            ?.Rejected_Applications?.Amount) ||
                          "---"}
                      </Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6 marginBottom={1}>Cancelled Applications</H6>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Number of Applications
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        {(data?.credit_applications_summary &&
                          data?.credit_applications_summary[5]
                            ?.Cancelled_Applications &&
                          data?.credit_applications_summary[5]
                            ?.Cancelled_Applications?.No_of_Applications) ||
                          "---"}
                      </Span>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Amount
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {(data?.credit_applications_summary &&
                          data?.credit_applications_summary[5]
                            ?.Cancelled_Applications &&
                          data?.credit_applications_summary[5]
                            ?.Cancelled_Applications?.Amount) ||
                          "---"}
                      </Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Credit Accounts Summary">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Total Credit Accounts
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.credit_accounts_summary &&
                    data?.credit_accounts_summary[0]?.Total_Credit_Accounts) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Open Accounts
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.credit_accounts_summary &&
                    data?.credit_accounts_summary[0]?.Open_Accounts) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Closed Accounts
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.credit_accounts_summary &&
                    data?.credit_accounts_summary[0]?.Closed_Accounts) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Adverse Accounts
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.credit_accounts_summary &&
                    data?.credit_accounts_summary[0]?.Adverse_Accounts) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Worst Active days in Arrears
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.credit_accounts_summary &&
                    data?.credit_accounts_summary[0]?.Worst_Days_in_Arreas) ||
                    "---"}
                </Span>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6 marginBottom={1}>Credit Accounts</H6>
                    </Grid>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Loan Amount
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {(data?.credit_accounts_summary &&
                          data?.credit_accounts_summary[1]?.Credit_Accounts &&
                          data?.credit_accounts_summary[1]?.Credit_Accounts[0]
                            ?.Loan_Amount) ||
                          "---"}
                      </Span>
                    </Grid>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Loan Balance
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {(data?.credit_accounts_summary &&
                          data?.credit_accounts_summary[1]?.Credit_Accounts &&
                          data?.credit_accounts_summary[1]?.Credit_Accounts[0]
                            ?.Loan_Balance) ||
                          "---"}
                      </Span>
                    </Grid>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Amount Overdue
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {(data?.credit_accounts_summary &&
                          data?.credit_accounts_summary[1]?.Credit_Accounts &&
                          data?.credit_accounts_summary[1]?.Credit_Accounts[0]
                            ?.Amount_Overdue) ||
                          "---"}
                      </Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Credit Accounts Classification Summary">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Institution Type
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.credits_accounts_classification_summary &&
                    data?.credits_accounts_classification_summary[0]
                      ?.Institution_Type) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  No of Accounts
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.credits_accounts_classification_summary &&
                    data?.credits_accounts_classification_summary[0]
                      ?.No_of_Accounts) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Loan Amount
                </H6>
                <Span sx={styles.vlabelValue}>
                  UGX
                  {(data?.credits_accounts_classification_summary &&
                    data?.credits_accounts_classification_summary[0]
                      ?.Loan_Amount) ||
                    "---"}
                </Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Loan Balance
                </H6>
                <Span sx={styles.vlabelValue}>
                  UGX
                  {(data?.credits_accounts_classification_summary &&
                    data?.credits_accounts_classification_summary[0]
                      ?.Loan_Balance) ||
                    "---"}
                </Span>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Collateral Material Summary">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Total Collateral Material
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.collateral_material_summary &&
                    data?.collateral_material_summary[0]
                      ?.Total_Collateral_Material) ||
                    "---"}
                </Span>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6>Collateral Material</H6>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        No of Items
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        {(data?.collateral_material_summary &&
                          data?.collateral_material_summary[1]
                            ?.Collateral_Material &&
                          data?.collateral_material_summary[1]
                            ?.Collateral_Material[0]?.No_of_Items) ||
                          "---"}
                      </Span>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Value of Items
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {(data?.collateral_material_summary &&
                          data?.collateral_material_summary[1]
                            ?.Collateral_Material &&
                          data?.collateral_material_summary[1]
                            ?.Collateral_Material[0]?.Value_of_Items) ||
                          "---"}
                      </Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Collateral Guarantor Summary">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Total Collateral Guarantors
                </H6>
                <Span sx={styles.vlabelValue}>
                  {(data?.collateral_guarantor_summary &&
                    data?.collateral_guarantor_summary[0]
                      ?.Total_Collateral_Guarantors) ||
                    "---"}
                </Span>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6>Collateral Guarantor</H6>
                    </Grid>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        No of Guaranteed Accounts
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        {(data?.collateral_guarantor_summary &&
                          data?.collateral_guarantor_summary[1]
                            ?.Collateral_Guarantor &&
                          data?.collateral_guarantor_summary[1]
                            ?.Collateral_Guarantor[0]
                            ?.No_of_Guaranteed_Accounts) ||
                          "---"}
                      </Span>
                    </Grid>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Amount Guaranteed
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {(data?.collateral_guarantor_summary &&
                          data?.collateral_guarantor_summary[1]
                            ?.Collateral_Guarantor &&
                          data?.collateral_guarantor_summary[1]
                            ?.Collateral_Guarantor[0]?.Amount_Guaranteed) ||
                          "---"}
                      </Span>
                    </Grid>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Balance on Guaranteed Accounts
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        UGX
                        {(data?.collateral_guarantor_summary &&
                          data?.collateral_guarantor_summary[1]
                            ?.Collateral_Guarantor &&
                          data?.collateral_guarantor_summary[1]
                            ?.Collateral_Guarantor[0]
                            ?.Balance_on_Guaranteed_Accounts) ||
                          "---"}
                      </Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Bounced Cheques Summary">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Total Bounced Cheques
                </H6>
                <Span sx={styles.vlabelValue}>
                  {data?.bounced_cheques_summary &&
                    data?.bounced_cheques_summary[0]?.Total_Bounced_Cheques}
                </Span>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6>Bounced Cheques</H6>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        No of Bounced Cheques
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        {(data?.bounced_cheques_summary &&
                          data?.bounced_cheques_summary[1]?.Bounced_Cheques &&
                          data?.bounced_cheques_summary[1]?.Bounced_Cheques
                            ?.No_of_Bounced_Cheques) ||
                          "---"}
                      </Span>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Value of Bounced Cheques{" "}
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        {(data?.bounced_cheques_summary &&
                          data?.bounced_cheques_summary[1]?.Bounced_Cheques &&
                          data?.bounced_cheques_summary[1]?.Bounced_Cheques
                            ?.Value_of_Bounced_Cheques) ||
                          "---"}
                      </Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Financial Fraud Summary">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Total Financial Malpractice
                </H6>
                <Span sx={styles.vlabelValue}>
                  {data?.financial_fraud_summary &&
                    data?.financial_fraud_summary[0]
                      ?.Total_Financial_Malpractice}
                </Span>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6>Bounced Cheques</H6>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        No of Bounced Cheques
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        {(data?.financial_fraud_summary &&
                          data?.financial_fraud_summary[1] &&
                          data?.financial_fraud_summary[1]
                            ?.Financial_Malpractice &&
                          data?.financial_fraud_summary[1]
                            ?.Financial_Malpractice[0]
                            ?.No_of_Financial_Malpractice) ||
                          "---"}
                      </Span>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Loss Amount
                      </H6>
                      <Span sx={styles.vlabelValue}>
                        {(data?.financial_fraud_summary &&
                          data?.financial_fraud_summary[1] &&
                          data?.financial_fraud_summary[1]
                            ?.Financial_Malpractice &&
                          data?.financial_fraud_summary[1]
                            ?.Financial_Malpractice[0]?.Loss_Amount) ||
                          "---"}
                      </Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <Button
          variant="outlined"
          color="primary"
          sx={{ width: "100%" }}
          onClick={() =>
            props.handleDownloadReport(props.currentFilters, "pdf")
          }
        >
          <DownloadIcon sx={{ marginRight: 1 }} />
          Download Score
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    backgroundColor: "#EFEFEF",
    borderRadius: "0.5rem",
  },
  labelText: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#000000",
  },
  labelValue: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#5F6368",
    display: "block",
  },
  vlabelText: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#000000",
  },
  vlabelValue: {
    fontSize: "1rem",
    fontWeight: 500,
    color: "#5F6368",
    display: "block",
  },
  iconCircle: {
    height: "4rem",
    width: "4rem",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  whiteCard: {
    height: "100%",
    padding: "1rem",
    backgroundColor: "#FFFFFF",
    borderRadius: "0.5rem",
    border: "1px solid #E5EAF2",
  },
};

export default CreditReportDetails;
