import React, { FC } from "react";
import FlexBox from "../../components/FlexBox";
import SuccessTestIcon from "../../assets/svg/file-check.svg";
import { H2 } from "../../components/Typography";
import { Theme, useMediaQuery } from "@mui/material";

export const SuccessPsychTest: FC = () => {
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <FlexBox
      data-aos="fade-up"
      data-aos-duration="800"
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: { sm: "100%" },
        padding: "20px",
      }}
    >
      <img
        width={downSm ? 150 : 300}
        src={SuccessTestIcon}
        alt="success-test"
      />
      <H2 sx={{ textAlign: "center", fontSize: downSm ? "14px" : "18px" }}>
        You successfully completed your Psychometric Test!
      </H2>
    </FlexBox>
  );
};
