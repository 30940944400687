import React, { FC } from "react";
import FlexBox from "../../components/FlexBox";
import CancelledTestIcon from "../../assets/svg/file-cross.svg";
import { Theme, useMediaQuery } from "@mui/material";
import { H2 } from "../../components/Typography";

export const CancelledPsychTest: FC = () => {
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <FlexBox
      data-aos="fade-up"
      data-aos-duration="800"
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: { sm: "100%" },
      }}
    >
      <img
        width={downSm ? 150 : 300}
        src={CancelledTestIcon}
        alt="success-test"
      />
      <H2 sx={{ textAlign: "center", fontSize: downSm ? "14px" : "18px" }}>
        Your Psychometric test has been cancelled.
      </H2>
    </FlexBox>
  );
};
