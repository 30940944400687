import { FC, useState } from "react";
import { H6, Span } from "../../../Typography";
import StatusBtn from "../../StatusBtn";
import { Box, Button, Grid } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FlexBox from "../../../FlexBox";
import { figureFormatter } from "../../../../utils/figure_formatter";
import ProgressIndicator from "../../ProgressIndicator";

interface ResultsPhoneProps {
  data: any;
  handleTryAgain?: () => Promise<void>;
  handleIfMatched?: () => Promise<void>;
  handleIfNotMatched?: () => Promise<void>;
  confirming?: boolean;
}

const ResultsPhone: FC<ResultsPhoneProps> = ({
  data,
  handleTryAgain,
  handleIfMatched,
  handleIfNotMatched,
  confirming,
}) => {
  const [selection, setSelection] = useState({
    type: "",
    isDone: false,
  });

  const submitMatch = async () => {
    setSelection({
      ...selection,
      type: "Match",
    });
    if (handleIfMatched) await handleIfMatched();
    setSelection({
      ...selection,
      isDone: true,
    });
  };

  const submitNotMatch = async () => {
    setSelection({
      ...selection,
      type: "NotMatch",
    });
    if (handleIfNotMatched) await handleIfNotMatched();
    setSelection({
      ...selection,
      isDone: true,
    });
  };

  return (
    <Box>
      <Box sx={styles.container} marginBottom={2}>
        <FlexBox gap={2} alignItems={"center"}>
          <Box sx={styles.iconCircle}>
            <AccountCircleIcon
              color="primary"
              sx={{ height: "3.8rem", width: "3.8rem" }}
            />
          </Box>
          <FlexBox flexDirection={"column"} gap={0.8}>
            <H6 marginBottom={0}>Phone Number:</H6>
            <Span sx={styles.labelValue}>+{data?.parameter?.phonenumber}</Span>
          </FlexBox>
        </FlexBox>
        <FlexBox
          flexDirection={"column"}
          gap={0.8}
          sx={{ textAlign: "center" }}
        >
          <H6 marginBottom={0}>Phone Status:</H6>
          <StatusBtn
            type={
              data?.result?.status === "FAILED" || data?.status == 5 ? 0 : 1
            }
            title={
              data?.result?.status === "FAILED" || data?.status == 5
                ? data?.result?.message || "FAILED"
                : "FOUND"
            }
          />
        </FlexBox>
      </Box>

      <Box sx={styles.container} marginBottom={2}>
        <Grid container rowSpacing={2.5}>
          <Grid item xs={6}>
            <H6 marginBottom={0} sx={styles.labelText}>
              Surname
            </H6>
            <Span sx={styles.labelValue}>
              {data?.result?.surname ? data?.result?.surname : "---"}
            </Span>
          </Grid>
          <Grid item xs={6}>
            <H6 marginBottom={0} sx={styles.labelText}>
              Given Name
            </H6>
            <Span sx={styles.labelValue}>
              {data?.result?.firstname ? data?.result?.firstname : "---"}
            </Span>
          </Grid>

          <Grid item xs={6}>
            <H6 marginBottom={0} sx={styles.labelText}>
              Middle Name
            </H6>
            <Span sx={styles.labelValue}>
              {data?.result?.middlename ? data?.result?.middlename : "---"}
            </Span>
          </Grid>
          <Grid item xs={6}>
            <H6 marginBottom={0} sx={styles.labelText}>
              Cost of Check
            </H6>
            <Span sx={styles.labelValue}>
              {data?.service_cost
                ? figureFormatter({
                    figure: data?.service_cost,
                    currency: "UGX",
                  })
                : "---"}
            </Span>
          </Grid>
        </Grid>
      </Box>

      {data?.result?.status === "SUCCESSFUL" && !selection.isDone && (
        <FlexBox alignItems={"center"} justifyContent={"flex-end"} gap={2}>
          {selection.type !== "Match" && (
            <Button
              size="small"
              color="inherit"
              variant="contained"
              sx={{ backgroundColor: "#FFFFFF", border: "1px solid #D9D9D9" }}
              onClick={submitNotMatch}
            >
              {confirming ? (
                <>
                  <ProgressIndicator color="inherit" size={20} />{" "}
                  <Span style={{ marginLeft: "0.5rem" }} color="primary">
                    Loading...
                  </Span>
                </>
              ) : (
                "Not a Match"
              )}
            </Button>
          )}

          {selection.type !== "NotMatch" && (
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={submitMatch}
            >
              {confirming ? (
                <>
                  <ProgressIndicator color="inherit" size={20} />{" "}
                  <Span style={{ marginLeft: "0.5rem" }} color="primary">
                    Loading...
                  </Span>
                </>
              ) : (
                "Yes, It is a Match"
              )}
            </Button>
          )}
        </FlexBox>
      )}

      {data?.result?.status === "FAILED" && handleTryAgain && (
        <FlexBox alignItems={"center"} justifyContent={"flex-start"} gap={2}>
          <Button
            size="small"
            color="inherit"
            variant="contained"
            sx={{ backgroundColor: "#FFFFFF", border: "1px solid #D9D9D9" }}
            onClick={handleTryAgain}
          >
            Try Again
          </Button>
        </FlexBox>
      )}
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    backgroundColor: "#EFEFEF",
    borderRadius: "0.5rem",
  },
  labelText: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#000000",
  },
  labelValue: {
    fontSize: "1rem",
    fontWeight: 500,
    color: "#5F6368",
  },
  iconCircle: {
    height: "4rem",
    width: "4rem",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default ResultsPhone;
