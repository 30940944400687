import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPaginationState {
  page: number;
  limit: number;
  count: number;
  lastVisitedPage: number;
  paginatedData: Record<
    number,
    {
      data: any[];
      filters: Record<string, string | number>;
      searchQuery: string;
      reportTotals?: any;
    }
  >;
  filters: Record<string, string | number>;
  searchQuery: string;
}

const initialState: IPaginationState = {
  page: 1,
  limit: 10,
  count: 0,
  lastVisitedPage: 1,
  paginatedData: {},
  filters: {},
  searchQuery: "",
};

const PaginationSlice = createSlice({
  name: "LoanBookReportPagination",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setPaginatedData: (
      state,
      action: PayloadAction<{ page: number; data: any; reportTotals: any }>,
    ) => {
      const {
        page,
        data,
        reportTotals,
      }: { page: number; data: any[]; reportTotals: any } = action.payload;
      state.paginatedData[page] = {
        data,
        filters: state.filters,
        searchQuery: state.searchQuery,
        reportTotals: reportTotals,
      };
    },
    setLastVisitedPage: (state, action: PayloadAction<number>) => {
      state.lastVisitedPage = action.payload;
    },
    clearPaginatedData: (state) => {
      state.paginatedData = {};
    },
    setFilters: (
      state,
      action: PayloadAction<Record<string, string | number>>,
    ) => {
      state.filters = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
  },
});

export const {
  setPage,
  setLimit,
  setPaginatedData,
  setLastVisitedPage,
  setCount,
  clearPaginatedData,
  setFilters,
  setSearchQuery,
} = PaginationSlice.actions;

export const selectPagination = (state: {
  LoanBookReportPagination: IPaginationState;
}) => state.LoanBookReportPagination;

export default PaginationSlice.reducer;
