import { Box, Grid } from "@mui/material";
import { FC } from "react";
import FormTextInput from "./FormComponents/FormTextInput";
import FormSelectInput from "./FormComponents/FormSelectInput";
import FormCheckbox from "./FormComponents/FormCheckBox";
import {
  IFormField,
  deepDerefrencerValidations,
} from "../../utils/form_factory";
import { FormDateInput } from "./FormComponents/FormDateInput";
import FormTextArea from "./FormComponents/FormTextArea";
import FormPhoneInput from "./FormComponents/FormPhoneInput";
import FormFileInput from "./FormComponents/FormFileInput";
import FormClientAccount from "./FormComponents/FormClientAccount";
import FormSelectSearchInput from "./FormComponents/FormSelectSearch";
import FormImageCropper from "./FormComponents/FormImageCropper";
import { FormDateTimeInput } from "./FormComponents/FormDateTimeInput";
import FormBranchField from "./FormComponents/FormBranchField";
import FormNinInput from "./FormComponents/FormNinInput";
import FormAsyncSelectSearch from "./FormComponents/FormAsyncSelectSearch";
import FormMultiSelectSearchInput from "./FormComponents/FormMultiSelectSearch";
import FormIppnsInput from "./FormComponents/FormIppnsInput";
import FormRadioBtn from "./FormComponents/FormRadioBtn";
import ConcatenatedTextSelect from "./FormComponents/InputGroupSelect";
import ConcatenatedSelectSelect from "./FormComponents/SelectGroupSelect";
import InputGroupText from "./FormComponents/InputGroupText";
import MultiInputField from "./FormComponents/MultiInputField";
import FormInputTextAutoCompletion from "./FormComponents/FormInputTextWithAutoCompletion";
import FlexBox from "../FlexBox";
import VerificationButton, {
  BtnDisplayType,
  VerificationType,
} from "./KYC/VerificationButton/VerificationButton";
import IsGranted from "../../guards/IsGranted";
import { PERMISSIONS } from "../../constants/permissions";
interface IFormFactoryProps {
  formikInstance: any;
  formFields: IFormField[];
  validationSchema?: any;
  others?: any;
  isCustomFormInstance?: boolean;
}

export const FormTypeFactory = (
  formField: IFormField,
  formikInstance: any,
  validationSchema: any,
  others: any,
) => {
  const isFieldRequired = (fieldName: string) => {
    return deepDerefrencerValidations(validationSchema, fieldName)?.tests?.some(
      (test: any) => test.OPTIONS.name === "required",
    );
  };

  switch (formField.uiType) {
    case "text":
      return (
        <FormTextInput
          {...formField}
          type={formField.type}
          formControl={formikInstance}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );
    case "ippns":
      return (
        <FormIppnsInput
          {...formField}
          type={formField.type}
          formControl={formikInstance}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );
    case "phone":
      return (
        <FormPhoneInput
          {...formField}
          formControl={formikInstance}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );
    case "nin":
      return (
        <FormNinInput
          {...formField}
          formControl={formikInstance}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );
    case "file":
      return (
        <FormFileInput
          {...formField}
          formControl={formikInstance}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );
    case "file-cropper":
      return (
        <FormImageCropper
          {...formField}
          formControl={formikInstance}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );
    case "client-account":
      return (
        <FormClientAccount
          {...formField}
          multipleAccounts={formField.multipleAccounts ? true : false}
          formControl={formikInstance}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );
    case "date":
      return (
        <FormDateInput
          {...formField}
          format={others.format}
          formControl={formikInstance}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );
    case "datetime":
      return (
        <FormDateTimeInput
          {...formField}
          format={others.format}
          formControl={formikInstance}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );
    case "select":
      return (
        <FormSelectInput
          {...formField}
          formControl={formikInstance}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );
    case "select-search":
      return (
        <FormSelectSearchInput
          {...formField}
          formControl={formikInstance}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );
    case "multi-select-search":
      return (
        <FormMultiSelectSearchInput
          {...formField}
          formControl={formikInstance}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );
    case "async-select-search":
      return (
        <FormAsyncSelectSearch
          {...formField}
          formControl={formikInstance}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );
    case "branch-field":
      return (
        <FormBranchField
          {...formField}
          formControl={formikInstance}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );
    case "checkbox":
      return (
        <FormCheckbox
          formControl={formikInstance}
          {...formField}
          // check if label is a string or a react node
          label={
            typeof formField.label === "string"
              ? `${formField.label} ${
                  isFieldRequired(formField.name) ? "*" : ""
                }`
              : formField.label
          }
        />
      );

    case "radiobtn":
      return (
        <FormRadioBtn
          formControl={formikInstance}
          {...formField}
          label={
            typeof formField.label === "string"
              ? `${formField.label} ${
                  isFieldRequired(formField.name) ? "*" : ""
                }`
              : formField.label
          }
        />
      );
    case "textarea":
      return (
        <FormTextArea
          formControl={formikInstance}
          {...formField}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );

    case "text&select":
      return (
        <ConcatenatedTextSelect
          formControl={formikInstance}
          parentInput={(formField as any).parentInput}
          secondaryInput={(formField as any).secondaryInput}
        />
      );
    case "multtext":
      return (
        <MultiInputField
          {...formField}
          formControl={formikInstance}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );
    case "textAuto":
      return (
        <FormInputTextAutoCompletion
          {...formField}
          formControl={formikInstance}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );

    default:
      return (
        <FormTextInput
          formControl={formikInstance}
          {...formField}
          label={`${formField.label} ${
            isFieldRequired(formField.name) ? "*" : ""
          }`}
        />
      );
  }
};

export const GroupTypeFactory = (
  formField: IFormField,
  formikInstance: any,
  validationSchema: any,
  others: any,
) => {
  switch (formField.uiType) {
    case "textselect":
      return (
        <ConcatenatedTextSelect
          formControl={formikInstance}
          parentInput={(formField as any).parentInput}
          secondaryInput={(formField as any).secondaryInput}
          {...formField}
        />
      );
    case "texttext":
      return (
        <InputGroupText
          formControl={formikInstance}
          parentInput={(formField as any).parentInput}
          secondaryInput={(formField as any).secondaryInput}
          {...formField}
        />
      );
    case "selectselect":
      return (
        <ConcatenatedSelectSelect
          formControl={formikInstance}
          parentInput={(formField as any).parentInput}
          secondaryInput={(formField as any).secondaryInput}
          {...formField}
        />
      );
    default:
      return <></>;
  }
};

const FormFactory: FC<IFormFactoryProps> = ({
  formFields,
  formikInstance,
  validationSchema,
  others,
  isCustomFormInstance = false,
}) => {
  const groupedFields = formFields.filter((field) => field.isGrouped);
  const nonGroupedFields = formFields.filter((field) => !field.isGrouped);

  return (
    <Grid
      {...others}
      container
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      {nonGroupedFields.map((field: IFormField, index: number) => {
        return (
          <Grid
            sx={field.isHidden ? styles.hidden : {}}
            key={index}
            item
            {...field.uiBreakpoints}
          >
            {field.withVerification ? (
              <FlexBox sx={{ gap: 2, alignItems: "center" }}>
                {FormTypeFactory(
                  field,
                  isCustomFormInstance
                    ? formikInstance(field, index)
                    : formikInstance,
                  validationSchema,
                  { ...others, format: field.dateFormat },
                )}
                <IsGranted
                  permissions={[
                    PERMISSIONS.VIEW_KYC_PHONE_NUMBER,
                    PERMISSIONS.VIEW_KYC_VALIDATE_NIN,
                  ]}
                >
                  <VerificationButton
                    formField={field}
                    formInstance={formikInstance}
                    verificationType={
                      field.verificationType as VerificationType
                    }
                    phoneType={field.phoneVerificationType}
                    displayType={BtnDisplayType.DETAILS}
                  />
                </IsGranted>
              </FlexBox>
            ) : (
              <>
                {FormTypeFactory(
                  field,
                  isCustomFormInstance
                    ? formikInstance(field, index)
                    : formikInstance,
                  validationSchema,
                  { ...others, format: field.dateFormat },
                )}
              </>
            )}
          </Grid>
        );
      })}

      {groupedFields.map((field: IFormField, index: number) => {
        return (
          <Grid
            sx={field.isHidden ? styles.hidden : {}}
            key={index}
            item
            {...field.uiBreakpoints}
          >
            {GroupTypeFactory(
              field,
              isCustomFormInstance
                ? formikInstance(field, index)
                : formikInstance,
              validationSchema,
              { ...others, format: field.dateFormat },
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FormFactory;

const styles = {
  hidden: {
    display: "none",
  },
};
