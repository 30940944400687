import { Button, Popover } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import uniqueId from "../../utils/generateId";

interface IPopoverWrapperProps {
  contextData?: any;
  anchorOrigin?: {
    vertical: "top" | "bottom";
    horizontal: "left" | "right";
  };
  Trigger: FC<{
    id: string;
    contextData?: any;
  }>;
  Body: FC<any> | string;
  anchorElId?: string;
  isDisabled?: boolean;
}

const PopoverWrapper: FC<IPopoverWrapperProps> = ({
  Trigger,
  Body,
  contextData,
  anchorOrigin,
  anchorElId,
  isDisabled,
}) => {
  const [bodyWidth, setBodyWidth] = useState<number>(0);
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      setBodyWidth(ref.current.offsetWidth);
    }
  }, [ref.current]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = anchorElId || `popover-id-${uniqueId()}`;

  return (
    <div className="table-visible-actions">
      <Button
        ref={ref}
        aria-describedby={id}
        variant="contained"
        onClick={(event) => handleClick(event)}
        style={{
          padding: 0,
          height: "auto",
          width: "100%",
          backgroundColor: "white",
          border: "none !important",
        }}
        disabled={isDisabled}
      >
        <Trigger id={id} contextData={contextData} />
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={
          anchorOrigin || {
            vertical: "bottom",
            horizontal: "left",
          }
        }
      >
        <Body onClose={handleClose} bodyWidth={bodyWidth - 2} />
      </Popover>
    </div>
  );
};

export default PopoverWrapper;
