import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

export interface IPaginationState {
  page: number;
  limit: number;
  count: number;
  lastVisitedPage: number;
  paginatedData: {
    data?: any;
    dates: any[];
    filters?: Record<string, string | number>;
    reportModeStatus?: boolean;
  };
  filters: Record<string, string | number>;
  searchQuery: string;
  reportModeStatus?: boolean;
}

const initialState: IPaginationState = {
  page: 1,
  limit: 10,
  count: 0,
  lastVisitedPage: 1,
  paginatedData: {
    dates: [],
    reportModeStatus: false,
  },
  filters: {
    start_date: moment(new Date()).format("YYYY-MM-DD"),
    end_date: moment(new Date()).format("YYYY-MM-DD"),
  },
  searchQuery: "",
};

const PaginationSlice = createSlice({
  name: "BalanceSheetReportPagination",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setPaginatedData: (
      state,
      action: PayloadAction<{ data: any; dates: any }>,
    ) => {
      const { data, dates }: any = action.payload;
      state.paginatedData = {
        data,
        dates: dates || [],
        filters: state.filters,
        reportModeStatus: state.reportModeStatus,
      };
    },
    setLastVisitedPage: (state, action: PayloadAction<number>) => {
      state.lastVisitedPage = action.payload;
    },
    clearPaginatedData: (state) => {
      state.paginatedData = {
        dates: [],
      };
    },
    setFilters: (
      state,
      action: PayloadAction<Record<string, string | number>>,
    ) => {
      state.filters = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setReportModeStatus: (state, action: PayloadAction<boolean>) => {
      state.reportModeStatus = action.payload;
    },
  },
});

export const {
  setPage,
  setLimit,
  setPaginatedData,
  setLastVisitedPage,
  setCount,
  clearPaginatedData,
  setFilters,
  setSearchQuery,
  setReportModeStatus,
} = PaginationSlice.actions;

export const selectPagination = (state: {
  BalanceSheetReportPagination: IPaginationState;
}) => state.BalanceSheetReportPagination;

export default PaginationSlice.reducer;
