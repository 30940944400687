import { FC } from "react";
import { InputBaseProps } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import StyledInputBase from "../StyledInputBase";

const TableSearchInput: FC<InputBaseProps> = (props) => {
  return (
    <StyledInputBase
      {...props}
      sx={{
        justifyContent: "initial",
        ...props.sx,
      }}
      startAdornment={
        <SearchIcon
          sx={{
            fontSize: 16,
            marginRight: 1,
            color: "text.disabled",
          }}
        />
      }
    />
  );
};

export default TableSearchInput;
