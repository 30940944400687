import * as React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import moment from "moment";

import { IFormField, deepDerefrencer } from "../../../utils/form_factory";
import { Small } from "../../Typography";
import { ukoTheme } from "../../../theme";
import useInstitution from "../../../hooks/useInstitution";
import { useAuth } from "../../../hooks/useAuth";

interface IFormDateInputProps extends Partial<IFormField> {
  name: string;
  formControl: any;
  format?: string;
  applyDateLowerLimit?: boolean;
  applyDateUpperLimit?: boolean;
  maxDate?: Date;
  minDate?: Date;
  isDisabled?: boolean;
  isHidden?: boolean;
}

export const FormDateInput: React.FC<IFormDateInputProps> = ({
  name,
  label,
  formControl,
  format,
  maxDate,
  minDate,
  isDisabled,
  applyDateLowerLimit = true,
  applyDateUpperLimit = true,
}: IFormDateInputProps) => {
  const theme = ukoTheme();
  const institution = useInstitution();
  const auth: any = useAuth();

  const staffBackDateDays = auth?.user?.extra_details?.allowed_back_date_days;
  const institutionBackDateDays = institution?.allowed_back_date_days;

  const allowNoOfBackDays =
    staffBackDateDays !== undefined && staffBackDateDays !== null
      ? staffBackDateDays
      : institutionBackDateDays;

  const minDateLimit =
    applyDateLowerLimit && allowNoOfBackDays !== undefined
      ? moment(new Date()).subtract(allowNoOfBackDays, "days")
      : undefined;
  const maxDateLimit = applyDateUpperLimit
    ? moment(new Date()).add(0, "days")
    : undefined;

  const isFieldRequired = (fieldName: string) => {
    return String(formControl.getFieldMeta(name)?.error).match(/required/gi)
      ? true
      : false;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {
        <DatePicker
          sx={{
            ...styles.customStyles(
              theme,
              isFieldRequired(name) ? theme.palette.primary.main : undefined,
            ),
          }}
          label={label}
          value={
            deepDerefrencer(formControl.values, name)
              ? moment(deepDerefrencer(formControl.values, name) || undefined)
              : null
          }
          maxDate={maxDate ? moment(maxDate) : maxDateLimit || undefined}
          minDate={minDate ? moment(minDate) : minDateLimit || undefined}
          onChange={(date: any) => {
            if (date)
              return formControl.setFieldValue(
                name,
                date.format(format || "YYYY-MM-DD"),
              );
          }}
          format={format ?? "YYYY-MM-DD"}
          disabled={isDisabled}
        />
      }

      {Boolean(
        deepDerefrencer(formControl.touched, name) &&
          deepDerefrencer(formControl.errors, name),
      ) && (
        <Small
          color="error.main"
          fontSize="0.75rem"
          fontWeight={"normal"}
          marginTop={1}
        >
          {deepDerefrencer(formControl.errors, name)}
        </Small>
      )}
    </LocalizationProvider>
  );
};

const styles = {
  customStyles: (theme: any, outlineColor?: string) => ({
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "2px solid",
      borderColor: outlineColor
        ? outlineColor
        : theme.palette.mode === "light"
        ? theme.palette.secondary[300]
        : theme.palette.divider,
    },

    "& input[type='file'].MuiInputBase-input": {
      marginLeft: "120px",
    },
  }),
};
