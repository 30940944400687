import * as React from "react";
import Box from "@mui/material/Box";
import { AlertMessage } from "./AlertMessage";

interface MessageProps {
  title?: string;
  nonFieldErrors?: any;
  closeAlert?: () => void;
}

export const NonFieldErrorsAlertMessage: React.FC<MessageProps> = ({
  title,
  nonFieldErrors,
  closeAlert,
}: MessageProps) => {
  return (
    <>
      {nonFieldErrors && nonFieldErrors?.length > 0 && (
        <Box>
          <AlertMessage
            title={title}
            isOpen={nonFieldErrors.length > 0}
            message={
              <ul style={{ padding: 0, margin: 0 }}>
                {nonFieldErrors.map((error: string, index: number) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            }
            type="error"
            closeAlert={() => closeAlert && closeAlert()}
          />
        </Box>
      )}
    </>
  );
};

const styles = {
  marginBottom: 2,
};
