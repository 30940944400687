import { FC } from "react";
import { useAuth } from "../hooks/useAuth";
import { isGranted } from "../utils/is_granted";
import { hasAccess } from "../utils/has_access";
import useInstitution from "../hooks/useInstitution";

interface IIsGrantedProps {
  children: any;
  permissions: any[];
  requiredUsers?: any[];
}

export const IsGranted: FC<IIsGrantedProps> = ({
  children,
  permissions,
  requiredUsers,
}) => {
  const { user }: any = useAuth();
  const institution = useInstitution();

  const canAccess =
    hasAccess(institution, permissions) &&
    isGranted(user, permissions, (requiredUsers = []));

  if (!canAccess) {
    return null;
  }

  return <>{children}</>;
};

export default IsGranted;
