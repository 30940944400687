/* eslint-disable react-hooks/exhaustive-deps */
import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { FC, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useLocation, useRoutes } from "react-router-dom";
import routes from "./routes/routes";
import { ukoTheme } from "./theme";
import InternetConnection from "./components/UI/InternetConnection";
import { firebaseAuthentication } from "./utils/firebase";
import { useAuth } from "./hooks/useAuth";
import { CLIENT_ROUTES, LOANS_ROUTES } from "./constants/branch-configs";

const App: FC = () => {
  const { fbtoken }: any = useAuth();
  const allPages = useRoutes(routes);
  const location = useLocation();

  // get the previous route before the current route
  const getPreviousRoute = () => {
    const previousRoute = sessionStorage.getItem("previousRoute");
    return previousRoute ? previousRoute : "";
  };

  const checkClientRoutes = () => {
    return (
      CLIENT_ROUTES.includes(getPreviousRoute()) &&
      CLIENT_ROUTES.includes(location.pathname)
    );
  };

  const checkLoanRoutes = () => {
    return (
      LOANS_ROUTES.includes(getPreviousRoute()) &&
      LOANS_ROUTES.includes(location.pathname)
    );
  };

  const cleanUpGlobalBranchState = () => {
    if (checkClientRoutes() || checkLoanRoutes()) return;
    sessionStorage.removeItem("branch");
  };

  useEffect(() => {
    cleanUpGlobalBranchState();

    return () => {
      sessionStorage.setItem("previousRoute", location.pathname);
    };
  }, [location.pathname]);

  // App theme
  const appTheme = ukoTheme();

  // toaster options
  const toasterOptions = {
    style: {
      fontWeight: 500,
      fontFamily: "'Gilroy', sans-serif",
    },
  };

  useEffect(() => {
    const firebaseAuth = async () => {
      await firebaseAuthentication(fbtoken);
    };

    if (fbtoken) {
      firebaseAuth();
    }
  }, [fbtoken]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <InternetConnection />
        <Toaster toastOptions={toasterOptions} />
        {allPages}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
