import React, { FC } from "react";
import { VerificationType } from "./VerificationButton/VerificationButton";
import ResultsPhone from "./Results/ResultsPhone";
import ResultsNIN from "./Results/ResultsNIN";
import ResultsCreditScore from "./Results/ResultsCreditScore";
import ResultsValidateID from "./Results/ResultsValidateID";
import CreditReportProfiles from "./Results/ResultsCreditReport/CreditReportProfiles";

interface KYCResultsProps<T = any> {
  results: T;
  resultsType: VerificationType;
  handleRetryAgain?: () => Promise<void>;
  handleDownloadReport?: any;
  handleIfMatched?: () => Promise<void>;
  handleIfNotMatched?: () => Promise<void>;
  confirming?: boolean;
}

export const KYCResults: FC<KYCResultsProps> = (props) => {
  switch (props.resultsType) {
    case VerificationType.VERIFYNIN:
      return (
        <ResultsNIN
          data={props.results}
          handleTryAgain={props?.handleRetryAgain}
        />
      );
    case VerificationType.VERIFYID:
      return (
        <ResultsValidateID
          data={props.results}
          handleTryAgain={props?.handleRetryAgain}
        />
      );
    case VerificationType.VERIFYPHONE:
      return (
        <ResultsPhone
          handleIfMatched={props.handleIfMatched}
          handleIfNotMatched={props.handleIfNotMatched}
          handleTryAgain={props?.handleRetryAgain}
          data={props.results}
          confirming={props.confirming}
        />
      );
    case VerificationType.CHECKCREDITSCORE:
      return <ResultsCreditScore data={props.results} />;
    case VerificationType.CHECKCREDITREPORT:
      return (
        <CreditReportProfiles
          handleDownloadReport={props?.handleDownloadReport}
          profiles={props.results.profiles}
          requestPayload={props.results.requestPayload}
        />
      );
    default:
      return <div>Invalid results type</div>;
  }
};

export default KYCResults;
