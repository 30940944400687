import {
  PersonAdd as AddUserIcon,
  Login as LoginIcon,
  GridView as UserGridIcon,
} from "@mui/icons-material";
import SpeedIcon from "@mui/icons-material/Speed";
import SessionsIcon from "./Sessions";
import UserManagementIcon from "./UserManagement";
import UserProfileIcon from "./UserProfile";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

//new icons
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
//import logo from "../../assets/user.png";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BalanceIcon from "@mui/icons-material/Balance";
import CalculateIcon from "@mui/icons-material/Calculate";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import GroupsIcon from "@mui/icons-material/Groups";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PaidIcon from "@mui/icons-material/Paid";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import SavingsIcon from "@mui/icons-material/Savings";
import SmsIcon from "@mui/icons-material/Sms";
//import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import SchemaIcon from "@mui/icons-material/Schema";
//new icons
import WebAssetIcon from "@mui/icons-material/WebAsset";
import DeleteIcon from "@mui/icons-material/Delete";
import TaskIcon from "@mui/icons-material/Task";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginIcon,
  AddUserIcon,
  SessionsIcon,
  UserGridIcon,
  SpeedIcon,
  UserProfileIcon,
  UserManagementIcon,
  PersonOutlinedIcon,
  CalendarTodayOutlinedIcon,
  HelpOutlineOutlinedIcon,
  BarChartOutlinedIcon,
  PieChartOutlineOutlinedIcon,
  TimelineOutlinedIcon,
  MenuOutlinedIcon,
  MapOutlinedIcon,
  //logo,
  //SpaceDashboardIcon,
  GroupsIcon,
  PaidIcon,
  SavingsIcon,
  BalanceIcon,
  CalculateIcon,
  SmsIcon,
  PhoneAndroidIcon,
  AccountBalanceIcon,
  SupportAgentIcon,
  AccountBalanceWalletIcon,
  DriveFolderUploadIcon,
  StackedLineChartIcon,
  AdminPanelSettingsIcon,
  KeyboardArrowRightIcon,
  SupervisorAccountIcon,
  TaskAltIcon,
  SchemaIcon,
  WebAssetIcon,
  DeleteIcon,
  TaskIcon,
  AddReactionIcon,
  PersonSearchIcon,
};
